<template>
  <div class="table-wrapper">
    <table>
      <col style="width: 30%" />
      <col style="width: 10%" />
      <col style="width: 5%" />
      <col style="width: 35%" />
      <col style="width: 20%" />
      <col style="min-width: 100px" />
      <thead>
        <tr>
          <th>
            <button
              @click="
                tableSortByName('NAME', orderName);
                orderName === 'DESC' ? (orderName = 'ASC') : (orderName = 'DESC');
              "
              class="map_name-filter"
            >
              <span>{{ $t('pageBase.mapName') }}</span>
              <img
                :class="{ down: orderName === 'ASC' }"
                :src="require('@/assets/icons/arrow-down.svg')"
                alt="favourite"
                width="8px"
                height="5px"
              />
            </button>
          </th>
          <th>{{ $t('date') }}</th>
          <th>{{ $t('time') }}</th>
          <th>{{ $t('place') }}</th>
          <th>
            <button
              @click="
                tableSortByType('MAP_TYPE', orderType);
                orderType === 'DESC' ? (orderType = 'ASC') : (orderType = 'DESC');
              "
              class="map_type-filter"
            >
              <span>{{ $t('pageBase.mapType') }}</span>
              <img
                :class="{ down: orderType === 'ASC' }"
                :src="require('@/assets/icons/arrow-down.svg')"
                alt="favourite"
                width="8px"
                height="5px"
              />
            </button>
          </th>
          <th></th>
        </tr>
      </thead>
      <transition-group name="collapse-list" tag="tbody">
        <tr
          v-for="chart in chartHistory"
          @click.stop="onClick(chart)"
          :key="chart.chartId"
          :class="{ selected: chart.chartId === chartId }"
          @mouseenter="showEdit(chart.chartId, chart)"
          @mouseleave="hoveredItem = null"
        >
          <td>
            <div class="map_name">
              <span>{{ chart.mapName }}</span>
              <div class="map_name-types" v-if="chart.chartType === 'Synastry' && chart.secondPersonData">
                <span>{{ $t('pageBase.partner') }} 1:</span>
                <span>{{ $t('pageBase.partner') }} 2:</span>
              </div>
              <div
                class="map_name-types"
                v-else-if="chart.chartData && chart.chartType !== 'Synastry' && chart.secondPersonData"
              >
                <span>{{ $t(mapTypesCurrent(chart.chartData.maps[0].type)) }}:</span>
                <span>{{ $t(mapTypesCurrent(chart.chartData.maps[1].type)) }}:</span>
              </div>
            </div>
          </td>
          <td>
            <div>
              {{ chart.firstPersonData.date }}
            </div>
            <div v-if="chart.secondPersonData">
              {{ chart.secondPersonData.date }}
            </div>
          </td>
          <td>
            <div>
              {{ chart.firstPersonData.time | cutSeconds }}
            </div>
            <div v-if="chart.secondPersonData">
              {{ chart.secondPersonData.time | cutSeconds }}
            </div>
          </td>
          <td>
            <div v-if="chart.firstPersonData.place">
              {{ chart.firstPersonData.place }}
            </div>
            <div v-if="chart.secondPersonData && chart.secondPersonData.place">
              {{ chart.secondPersonData.place }}
            </div>
          </td>
          <td>
            {{ $t(chart.chartType.toUpperCase()) }}
          </td>
          <td>
            <div class="table-btns">
              <button
                v-show="hoveredItem === chart.chartId"
                class="table-btn favorite-btn"
                @click.stop="deleteItem(chart)"
              >
                <img :src="require('@/assets/icons/trash.svg')" alt="favourite" />
              </button>
              <button class="table-btn edit-btn" @click.stop="editItem(chart)">
                <img :src="require('@/assets/icons/pencil.svg')" alt="edit" />
              </button>
            </div>
          </td>
        </tr>
      </transition-group>
    </table>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ChartTypeTranslation } from '@/types';

export default {
  name: 'HistoryTable',
  data() {
    return {
      orderName: 'DESC',
      orderType: 'DESC',
      hoveredItem: null,
      ChartTypeTranslation,
    };
  },
  computed: {
    ...mapState('ChartModule', ['chartId', 'chartHistoryItems']),
    ...mapGetters('ChartModule', ['chartHistory']),
    sortedChartHistory() {
      return this.chartHistory.slice().sort((first, second) => second.chartId - first.chartId);
    },
  },
  methods: {
    ...mapActions('ChartModule', ['queueDeleteHistoryItem', 'revertDeleteHistoryItem', 'loadChart']),
    ...mapActions('UserModule', ['addNotification']),
    onClick(item) {
      this.$emit('selected', item);
    },
    deleteItem(item) {
      this.$emit('show-delete', item);
    },

    editItem(item) {
      this.$emit('edit-current', item);
    },

    tableSortByName(sort, order) {
      this.$emit('sort', sort, order);
    },
    tableSortByType(sort, order) {
      this.$emit('sort', sort, order);
    },

    showEdit(index) {
      this.hoveredItem = index;
    },
    mapTypes(chart) {
      if (chart === 'Натальная') {
        return 'Натальная карта';
      } else if (chart === 'Синастрия') {
        return 'Синастрия';
      } else if (chart === 'Соляр') {
        return 'Прогностика: Соляр';
      } else if (chart === 'Транзит') {
        return 'Прогностика: Транзит';
      } else if (chart === 'Дирекция') {
        return 'Прогностика: Дирекция';
      } else if (chart === 'Прогрессия') {
        return 'Прогностика: Прогрессия';
      }
    },

    mapTypesCurrent(map) {
      if (map === 'Natal') {
        return 'NATAL';
      } else if (map === 'Synastry') {
        return 'SYNASTRY';
      } else {
        return 'pageBase.prognosis';
      }
    },
  },
};
</script>
<style lang="sass" scoped>
$table-light-blue: blue-color('200')
$table-light-grey: #F2F2F2
$border-radius-value: base-unit(5)


.btn_test
  border:1px solid gray
  padding: 5px

.input_test
  border:1px solid gray
  padding: 5px

.table-wrapper
  width: 100%

table
  border-spacing: 0
  width: 100%
  color: grey-color('500')

thead
  background-color: transparent
  font-size: base-unit(16)

  th
    border-bottom: base-unit(2) solid $table-light-grey
    color: grey-color('500')
    font-weight: 400
    white-space: nowrap
    cursor: auto

    &:first-child
      border-top-left-radius:  $border-radius-value
      border-bottom-left-radius:  $border-radius-value

    &:last-child
      border-top-right-radius:  $border-radius-value
      border-bottom-right-radius:  $border-radius-value

tbody
  tr:hover
    background: $table-light-grey

  tr.selected
    background: $table-light-blue
    cursor: initial

th
  height: base-unit(58)
  text-align: left
  padding: 0 base-unit(20)

tr
  cursor: pointer
  height: base-unit(58)
  max-height: base-unit(58)

td
  min-width: base-unit(50)
  border-bottom: base-unit(2) solid $table-light-blue
  padding: base-unit(20) base-unit(20)
  font-size: base-unit(14)
  color: grey-color('600')
  +large-devices
    font-size: base-unit(16)
  &:first-child
    padding-right: 0
.delete-btn
  color: red-color()
  display: flex

  &:focus
    outline: none

  &:hover
    opacity: 0.5

.collapse-list-move,
.collapse-list-item,
.collapse-list-leave-active,
.collapse-list-enter-active
  transition: all 0.5s

.collapse-list-leave-to
  overflow: hidden
  display: block
  width: 0
  max-height: 0
  height: 0
  opacity: 0
.table-btns
  display: flex
  align-items: center
  justify-content: flex-end
  min-width: base-unit(110)

.table-btn
  display: flex
  align-items: center
  justify-content: center
  width: base-unit(26)
  height: base-unit(26)
  padding: base-unit(6)
  border-radius: base-unit(100)

  &:hover
    background-color: blue-color('100')
    +astro-box-shadow('small', rgba(0, 0, 0, 0.075))

  img
    width: 100%
    height: auto

  &:not(:last-child)
    margin-right: base-unit(12)

.map_type-filter, .map_name-filter
  display: flex
  align-items: center
  span
    margin-right: base-unit(10)

.map_name
  display: flex
  justify-content: space-between
  &-types
    display: flex
    flex-direction: column
    font-weight: 400
    font-size: base-unit(16)
    color: grey-color('400')
.down
  transform: rotate(180deg)

.favorite-btn
  margin-right: base-unit(12)
</style>
